.project-card .view-project {
    font-family: 'Space mono', monospace;
    font-size: 16px;
    transition: all 0.1s;
}

.project-card:hover .view-project {
    background: var(--lightest-grey);
    padding: 0 4px;
    border-radius: 3px;
}