.experience-item {
  display: flex;
  padding-bottom: 40px;
  align-items: stretch;
  position: relative;
}

.cv-download {
  margin-top: 16px;
}

.experience-item > .timeline {
  display: flex;
  justify-content: center;
  padding: 7px 24px 0 8px;
}

.timeline-circle {
  position: absolute;
  height: 12px;
  width: 12px;
  background: white;
  border: 2px solid var(--lighter-grey);
  border-radius: 50%;
}

.timeline-vertical-line {
  position: absolute;
  height: 100%;
  width: 2px;
  background-color: var(--lighter-grey);
}

.tools-title {
  width: 220px;
  display: flex;
  flex-direction: column;
  margin-right: 24px;
}

.experience-item-content {
  display: flex;
  flex-direction: column;
}

.experience-item-content > * {
  margin-bottom: 8px;
}

.tools {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
}

.tools > ul:first-of-type {
  margin-top: 24px;
}

@media only screen and (min-width: 768px) {  
  .tools {
    flex-direction: row;
  }

  .tools > ul:first-of-type {
    margin-top: 0;
  }

  .tools > ul:last-child {
    margin-left: 16px;
  }
}