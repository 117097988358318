/* Variables */

:root {
  --deliveroo-teal: #00A396;
  --better-green: #017848;
  --plain-black: #000000;
  --bulb-pink: #DF479B;
  --bbc-blue: #0075FF;
  --resolve-purple: #6937f5;
  --dark-grey: #2C2C2C;
  --mid-grey: #4D4D4D;
  --light-grey: #8C8C8C;
  --lighter-grey: #e1e1e1;
  --lightest-grey: #f5f5f5;
}

.contact {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
}

.project-card {
  display: flex;
  flex-direction: column;
  margin-bottom: 56px;
}

.project-card:last-child {
  margin-bottom: 0;
}

.project-card > a {
  color: var(--mid-grey);
  text-decoration: none;
}

/* .project-section {
  margin-bottom: 48px;
} */

.content-wrapper {
  width: 100%;
  padding: 96px 24px;
}

.project-page-wrapper > .content-wrapper {
  padding-top: 24px;
}

a:hover {
  cursor: pointer;
}

h5 a {
  text-decoration: none;
}

ul, ol {
  padding-left: 24px;
  margin: 0;
}

li {
  margin-bottom: 16px;
}

.divider-list {
  padding: 0;
}

.divider-list li {
  border-top: 1px solid var(--lighter-grey);
  padding: 16px 0;
  margin-bottom: 0;
  list-style:none;
}

.divider-list li:last-child {
  border-bottom: 1px solid var(--lighter-grey);
}

figure {
  margin: 0;
}

figcaption {
  line-height: 0;
}

img {
  border-radius: 8px;
}

/* Typography */

:root {
  font-size: 18px;
  font-weight: 400;
  line-height: 150%;
  color: var(--mid-grey);
}

h1 {
  font-size: 32px;
  line-height: 125%;
  color: var(--dark-grey);
}

h5 {
  font-size: 21px;
  font-weight: 700;
  margin-top: 48px;
  margin-bottom: 24px;
  color: var(--dark-grey);
}

.section-title {
  margin-top: 48px;
}

p {
  margin-top: 0;
  margin-bottom: 16px;
}

a {
  text-decoration-color: inherit;
  color: var(--light-grey);
}

a:visited {
  color: var(--mid-grey);
  text-decoration-color: var(--mid-grey);
}

/* Extra bold */
.eb {
  font-weight: 900;
}

.dg {
  color: var(--dark-grey);
  text-decoration-color: var(--dark-grey);
}

.intro, .selected-work { 
  margin-bottom: 48px;
}

.intro > p > a {
  border-bottom: 1px solid var(--light-grey);
  text-decoration: none;
}

.dt:hover {
  color: var(--deliveroo-teal);
  border-bottom: 1px solid var(--deliveroo-teal);
}

.pb:hover {
  color: var(--plain-black);
  border-bottom: 1px solid var(--plain-black);
}

.bg:hover {
  color: var(--better-green);
  border-bottom: 1px solid var(--better-green);
}

.bb:hover {
  color: var(--bbc-blue);
  border-bottom: 1px solid var(--bbc-blue);
}

.bp:hover {
  color: var(--bulb-pink);
  border-bottom: 1px solid var(--bulb-pink);
}

.rp:hover {
  color: var(--resolve-purple);
  border-bottom: 1px solid var(--resolve-purple);
}

.lg {
  color: var(--light-grey);
  text-decoration-color: var(--light-grey);
}

.small-text {
  font-size: 14px;
  line-height: 21px;
}

.no-ul {
  text-decoration: none;
}

.page-break {
  border: 0;
  border-top: 1px solid var(--lighter-grey)
}

@media only screen and (min-width: 768px) {
  .intro, .selected-work { 
    margin-bottom: 0;
  }

  .section-title {
    margin-top: 0;
  }

  .content-wrapper {
    display: grid;
    grid-template-columns: 100px minmax(0, 640px);
    gap: 148px 96px;
    padding: 96px 48px;
  }

  .project-page-wrapper > .content-wrapper {
    padding-top: 96px;
    gap: 32px 96px;
  }
}

@media only screen and (min-width: 1000px) {
  .content-wrapper {
    gap: 176px 96px;
    padding: 96px;
  }

  .project-page-wrapper > .content-wrapper {
    gap: 32px 96px;
  }
}