.back-button-wrapper {
  display: flex;
  gap: 8px;
  height: fit-content;
  align-items: center;
  text-decoration: none;
  margin-bottom: 24px;
}

.back-button-icon {
  border: 1px solid var(--lighter-grey);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  padding: 0 4px;
  align-items: center;
  transition: all 0.1s;
  height: 24px;
  width: 24px;
}

.back-button-text {
  font-family: 'Space Mono', monospace;
  font-size: 16px;
}

.back-button-wrapper:hover > .back-button-icon {
  background: var(--lightest-grey);
}

.project-page-title {
  margin: 0 0 32px 0;
}

.project-page-section {
  margin-bottom: 32px;
}

.back-to-projects-bottom {
  margin-top: 120px;
}
