.highlight {
  background: var(--lightest-grey);
  padding: 24px;
  border-radius: 8px;
  display: flex;
  margin-bottom: 16px;
}

.highlight-emoji {
  margin-right: 16px;
}