.project-card-compact {
    border-top: 1px solid var(--lighter-grey);
    display: flex;
    justify-content: space-between;
    padding: 16px 0;
    text-decoration: none;
    color: var(--mid-grey);
}

.project-card-compact > .arrow {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.1s;
    border-radius: 3px;
}

.project-card-compact:hover > .arrow {
    background: var(--lightest-grey);
}

.project-card-compact .title {
    transition: all 0.1s;
    width: fit-content;
}

.project-card-compact:hover .title {
    background: var(--lightest-grey);
    padding: 0 4px;
    border-radius: 3px;
}

.project-card-compact:last-child {
    border-bottom: 1px solid var(--lighter-grey);
}

.project-card-compact > .details {
    display: flex;
    flex-direction: column;
}

.project-card-compact > .details > .subtitle {
    color: var(--light-grey);
}