.double-image-wrapper {
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr;
  margin: 56px 0;
}

.double-image-wrapper img {
  max-width: 100%;
}

.single-image-wrapper a img:hover,
.double-image-wrapper a img:hover {
  cursor: zoom-in;
}

@media only screen and (min-width: 768px) {
  .double-image-wrapper {
    grid-template-columns: 1fr 1fr;
  }
}