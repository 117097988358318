.pill-deliveroo {
    background: #00ccbc;
    color: white;
}

.pill-bulb {
    background: #E12380;
    color: white;
}

.pill-personal {
    background: #FFD540;
    color: var(--dark-grey);
}

.pill {
    font-family: 'Space mono', monospace;
    border-radius: 32px;
    padding: 0 12px;
    width: fit-content;
    font-size: 12px;
    margin-top: 8px;
}