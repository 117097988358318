.header {
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;
  align-items: flex-start;
  font-size: 18px;
  font-weight: 400;
  line-height: 150%;
}

.intro-links-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 40px;
  font-size: 16px;
  transform: translateX(-8px);
}

.intro-link {
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  padding: 4px 8px;
  color: var(--mid-grey);
  fill: var(--light-grey);
  font-family: 'Space Mono', monospace;
  transition: all 0.1s;
}

.intro-link:hover {
  background: var(--lightest-grey);
}

.cv-link:hover {
  fill: #00A98B;
  color: #00A98B;
}

.linkedin-link:hover {
  fill: #005BB6;
  color: #005BB6;
}

.github-link:hover {
  fill: #000000;
  color: #000000;
}

.cv-content .intro {
  margin-bottom: 72px;
}

.header h5 {
  font-size: 18px;
  line-height: 150%;
  margin-top: 0;
}

.contact {
  margin-bottom: 16px;
}

.profile-pic {
  width: 140px;
  margin-bottom: 24px;
}

.project-card img {
  max-width: 100%;
}

.intro > p {
  font-size: 18px;
  font-weight: 400;
  line-height: 150%;
  margin-bottom: 32px;
}

.resume-link {
  margin-top: -16px;
}

@media only screen and (min-width: 768px) {
  .intro-links-wrapper {
    flex-direction: row;
    gap: 24px;
  }
}