.single-image-wrapper {
  width: 100%;
  min-width: 100%;
  max-width: 1000px;
  margin: 56px 0;
}

.single-image-wrapper img {
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .single-image-wrapper {
    width: calc(100vw - 96px);
    transform: translateX(-196px);
  }
}

@media only screen and (min-width: 1000px) {
  .single-image-wrapper {
    width: calc(100vw - 192px);
    transform: translateX(-196px);
  }
}